@import "styles/abstracts/index";
.content {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .row {
    @include lg-down() {
      flex-direction: column;
      gap: 2rem;
    }

    .imageWrapper {
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
